<template>
    <div id="content">
        <div class="card">
            <h3 class="card_title">Sonderkosten</h3>    
            <div id="infoGrid" class="padding">
                <p>Sondereinzelkosten:</p>
                <input type="number" :value="other.individual" @input="changeValue('individual', $event.target.value)">
                <p>€/Stück</p>

                <p>Sondergemeinkosten:</p>
                <input class="textspacing" type="number" :value="other.overhead" @input="changeValue('overhead', $event.target.value)">
                <p>€</p>

                <p>Gewinn:</p>
                <input class="textspacing" type="number" :value="other.margin" @input="changeValue('margin', $event.target.value)">
                <p>%</p>

                <p>Rabatt:</p>
                <input class="textspacing" type="number" :value="other.discount" @input="changeValue('discount', $event.target.value)">
                <p>%</p>
                
                <p>Skonto:</p>
                <input type="number" :value="other.cashdiscount" @input="changeValue('cashdiscount', $event.target.value)">
                <p>%</p>
                <p class="indent" v-if="other.cashdiscount != 0">Skontofrist:</p>
                <input v-if="other.cashdiscount != 0" type="number" :value="other.cashdiscount_days" @input="changeValue('cashdiscount_days', $event.target.value)">
                <p v-if="other.cashdiscount != 0">Tage</p>
            </div>
        </div>

        <div class="card">
            <h3 class="card_title">Sonstiges</h3>
            <div id="infoGrid" class="padding">
            
                <p>Ausschussquote:</p>
                <input type="number" :value="other.rejected" step="0.5" @input="changeValue('rejected', $event.target.value)">
                <p>%</p>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Other",
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeValue(key, value) {
            this.$store.commit("setOtherValue", [key, value]);
            this.$store.dispatch("refresh")
        }
    },
    computed: {
        other() {
            let values = this.$store.state.calculation.other
            if(!values.individual)values.individual = 0
            if(!values.overhead)values.overhead = 0
            if(!values.discount)values.discount = 0
            if(!values.cashdiscount)values.cashdiscount = 0
            return values
        },
    }
}
</script>

<style scoped>
    #infoGrid{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: .5em;
        grid-row-gap: 4px;
    }
    #infoGrid>*:nth-child(3n+2) {text-align: right;}
</style>